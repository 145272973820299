@use "../../../../assets/scss/main.scss" as *;

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;

  width: 728px;

  padding: 41.5px 60px;

  border-radius: 8px;

  background: $primary;

  @include iftablet {
    width: 100%;
  }

  @include ifmobile {
    padding: 24px 30px;
  }
}

.formHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;

  cursor: pointer;
}

.formInfo {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @include ifmobile {
    flex-direction: column;
  }
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 38.13px;

  color: $whiteGray;
}

.amountWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.amountTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  color: $whiteDark;
}

.amount {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;

  color: $whiteGray;
}

.selectorWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @include ifmobile {
    align-items: flex-start;
    flex-direction: column;
  }
}

.safeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  height: fit-content;

  padding: 2px 8px;

  border-radius: 4px;

  background: $transparent-green;
}

.safeText {
  font-size: 10px;
  font-weight: 400;
  line-height: 12.5px;

  color: $custom-secondary-green;

  opacity: 0.8;
}

.selector {
  width: 40%;

  @include ifmobile {
    width: 100%;
  }
}

.mainContent {
  display: flex;

  width: 100%;
  min-height: 400px;

  @include ifmobile {
    min-height: auto;
  }
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.emptyTitle {
  text-align: center;
}

.banksWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.laoder {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
