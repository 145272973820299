@use "../../../assets/scss/main.scss" as *;

.orderWrapper {
  display: grid;
  gap: 24px;

  padding: 24px 20px;
  border-radius: 16px;
  border: 1px solid $primary;

  background: $black;

  height: fit-content;

  box-shadow: $plans-card-shadow;

  h6 {
    font-family: $font-inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;

    color: $whiteDark;
  }

  @include ifmobile {
    padding: 24px 16px;
  }
}

.infoWrapper {
  display: grid;
  gap: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: $whiteGray;

    word-break: break-word;
  }

  span {
    opacity: 0.6;
  }

  .price {
    white-space: nowrap;

    @include ifmobile {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }

  @include ifmobile {
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
    }
  }
}

.price {
  white-space: nowrap;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  margin: 8px 0;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;

    color: $whiteGray;

    word-break: break-word;

    span {
      opacity: 0.8;
    }
  }

  a {
    font-weight: 500;

    text-decoration: underline;
  }
}
.total {
  display: flex;
  justify-content: space-between;

  p {
    font-family: $font-inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;

    color: $whiteDark;
  }
}

.separator {
  height: 1px;
  background: $custom-white-light;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
