@use "../../../assets/scss/main.scss" as *;

.blogSection {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 100px;

  flex: 1 0 auto;
  height: 100%;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 200px;

  padding: 0 15px;

  z-index: 1;
}

.blogLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.noOneArticlesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  height: 100%;

  text-align: center;
}
