@use "../../../../assets/scss/main.scss" as *;

.newsSection {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin: 0 auto;
  max-width: 2000px;
  width: 100%;

  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 70%;
  left: 0;

  transform: translateY(-50%);

  width: 80%;
  max-height: 100%;

  z-index: -1;

  @include ifmobile {
    width: 100%;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin: 0 auto;

  margin-top: 150px;
  max-width: $main-container-width;
  width: 100%;

  position: relative;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0 12px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    gap: 32px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}

.title {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: left;

  text-transform: uppercase;

  color: $whiteDark;

  @include iftablet {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;

    letter-spacing: normal;
  }
}

.searchWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 600px;

  @include iftablet {
    width: 100%;
  }
}

.input {
  padding-left: 40px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
