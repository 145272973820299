@use "../../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 140px;
  height: 104px;

  padding: 12px 14px;

  border-radius: 15px;

  background: $black;

  box-shadow: $course-modal;

  img {
    min-width: 1.5rem;
    max-width: 7.5rem;
    max-height: 2rem;
  }

  cursor: pointer;

  @include iftablet {
    overflow: hidden;
  }

  @include ifmobile {
    width: 120px;
    height: 100px;
  }
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  text-align: center;

  color: $whiteGray;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
