@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;

  width: 728px;

  padding: 41.5px 60px;

  border-radius: 15px;

  background: $blackLight;

  @include iftablet {
    width: 100%;
  }

  @include ifmobile {
    padding: 24px 24px;
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: $white;

  span {
    white-space: nowrap;
  }

  a {
    text-decoration: underline;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.listItem {
  list-style: disc;

  margin-left: 18px;
}

.link {
  text-decoration: underline;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;

  @include ifmobile {
    flex-direction: column;
  }
}

.cancelButton {
  padding: 14px 44px;

  border: 1px solid $white;

  span {
    color: $white;
  }
}
