// fonts:
// Lexend
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap");

// Inter
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

// Kensmark.03
@font-face {
  font-family: "Kensmark";
  src: url("../fonts/Kensmark-03-BoldSlant.otf") format("opentype");
}

// default colors
$white: #ffffff;
$whiteDark: #e8e8e8;
$whiteGray: #fdfdfd;
$whiteBlue: #fcfcfc;
$black: #000000;
$dark: #1d1d1d;
$blackLight: #212121;
$green: #83d17e;
$greenLight: #346654;
$gray: #c8c9cb;
$grayDark: #434343;
$grayMedium: #f4f4f4;
$grayLight: #9d9ea2;
$red: #ef4343;
$redDark: #981a17;
$brown: #543b40;
$brownDark: #342735;
$brownLight: #8d645c;

// custom colors
$primary: #003856;
$primary-blue: #0087cf;
$primary-light: #00385680;
$primary-dark: #001c2a;
$primary-black: #0f0f0f;
$neutral: #1a1e26;
$linear-gray: #545454;
$linear-black: rgba(84, 84, 84, 0);
$shadow-black: rgba(0, 0, 0, 0.25);
$transparent-black: #00000033;
$secondary-black: #101010;
$custom-white-gray: #fdfdfd4d;
$custom-gray-dark: #c4c4c4;
$transparent-gray: #e8e8e84d;
$custom-white-gray-light: #d9d9d9;
$custom-black-light: #00000080;
$custom-green: rgba(5, 66, 44, 0.4);
$custom-secondary-green: #34a853;
$custom-green-light: #648a7c;
$transparent-green: #1e900c4d;
$custom-gray: #c3d2cc;
$custom-gray-light: #858585;
$custom-black: rgba(33, 33, 33, 0.2);
$custom-black-dark: #333333;
$custom-secondary-black: #111726;
$custom-white: rgba(253, 253, 253, 0.4);
$custom-white-dark: #e8e8e866;
$custom-white-light: #e8e8e81a;

// table
$divider: rgba(0, 0, 0, 0.1);

// fonts
$font-main: "Lexend", sans-serif;
$font-kensmark: "Kensmark", sans-serif;
$font-inter: "Inter", sans-serif;

// buttons
$greenDark: #0d685b;
$greenMedium: #155c51;
$greenLight: #032e25;

// gradients
$black-gradient: linear-gradient(
  180deg,
  $dark 0px,
  $grayDark 50%,
  $linear-gray 100%
);

$green-gradient: linear-gradient(
  94deg,
  $greenDark 8.75%,
  $greenMedium 48.07%,
  $greenLight 108.29%
);

$brown-gradient: linear-gradient(
  135deg,
  $brownDark 0%,
  $brown 45%,
  $brownLight 100%
);

$gray-gradient: linear-gradient(180deg, $linear-gray 0%, $linear-black 100%);

// shadows
$discount-shadow: 0px 4px 50px 0px $shadow-black;
$checkbox-shadow: 0px 0px 0px 1px $red;
$plans-card-shadow: 0px 0px 24px 0px $black;
$course-menu-item: 8px 8px 24px 0px $secondary-black;
$course-modal: 0px 2px 10px 0px $transparent-black;

// polygons
$figure-polygon: polygon(75% 0%, 100% 0, 100% 99%, 0 99%, 0 75%);

// widths
$main-container-width: 1550px;
$tablet-container-width: 1024px;
$mobile-container-width: 768px;
