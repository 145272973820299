@use "../../../assets/scss/main.scss" as *;

.currentBlogSection {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  height: 100%;
}

.blogLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  max-width: 2000px;
  width: 100%;

  height: calc(100vh - 312px);
  max-height: 800px;
  min-height: 600px;

  overflow: hidden;

  position: relative;
}

.imageWrapper {
  width: 100%;
  height: 100%;

  height: calc(100vh - 312px);
  max-height: 800px;
  min-height: 600px;

  position: absolute;

  img {
    left: 50%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
  }
}

.backContainer {
  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;
  margin-top: 50px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    display: none;
  }
}

.backButton {
  background: $black;
}

.backBlock {
  width: 100%;
  height: 100%;

  background: $black;

  opacity: 0.2;
  position: absolute;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.blogWrapper {
  background: $black;

  border: 1px solid $primary;
  border-radius: 16px;

  box-shadow: $plans-card-shadow;

  padding: 64px;

  width: 60%;

  @include iftablet {
    width: 75%;

    padding: 48px 24px;
  }

  @include ifmobile {
    width: 95%;

    padding: 24px 16px;
  }
}

.title {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: center;

  color: $whiteDark;

  @include iftablet {
    font-size: 48px;
    line-height: 48.4px;

    letter-spacing: normal;
  }

  @include ifmobile {
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
  }
}

.mainContent {
  position: relative;
}

.mainText {
  display: flex;
  flex-direction: column;
  gap: 16px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin-top: 75px;
  margin-bottom: 200px;

  max-width: $tablet-container-width;
  width: 100%;

  padding: 0 15px;

  position: relative;

  z-index: 1;

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }

  p {
    font-family: $font-inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: -0.03em;

    @include ifmobile {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
    }
  }
}

.topBackgroundImage {
  position: absolute;
  top: 0;
  right: 5%;

  width: 570px;
  height: 300px;

  transform: rotate(75deg);

  @include iftablet {
    right: 0;
  }

  @include ifmobile {
    transform: rotate(0);

    width: 75%;
  }
}

.bottomBackgroundImage {
  position: absolute;
  bottom: 0;
  left: 5%;

  width: 570px;
  height: 300px;

  transform: rotate(-110deg);

  @include iftablet {
    right: 0;
    top: -70px;
  }

  @include ifmobile {
    width: 60%;
  }
}

.noOneArticlesBlock {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 32px;

    @include ifmobile {
      font-size: 24px;
    }
  }
}
