@use "../../../../assets/scss/main.scss" as *;

.diagramBlock {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: rotation 1.5s linear infinite;
}

.svgDiagram {
  position: absolute;
  left: 50%;
  top: 50%;

  width: 100%;
  height: 100%;

  transform: translate(-50%, -50%);
}

.progressCircle {
  transition: all 1s ease;
  stroke: $primary;
}

.restCircle {
  transition: all 1s ease;
  stroke: $white;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
