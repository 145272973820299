@use "../../assets/scss/main.scss" as *;

:global {
  .pure-modal-backdrop {
    background-color: rgba(0, 0, 0, 0);

    margin-left: auto;
    margin-right: auto;

    width: 100%;

    @include ifdesktopM {
      box-sizing: border-box;
    }

    @include iftablet {
      max-width: $mobile-container-width;
      gap: 24px;
    }

    .pure-modal {
      max-height: 95vh;

      @include ifmobile {
        width: 90%;
      }
    }
  }
}

.modal {
  padding-top: 8px;
  padding-bottom: 16px;

  :global {
    .panel {
      border-radius: 12px;
      overflow: hidden;

      box-shadow: $course-menu-item;
    }

    .panel-heading {
      background: $blackLight;
    }

    .close {
      background: none;

      top: 44px;
      right: 32px;
    }

    .panel-title {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;

      padding: 32px;
    }

    .panel-body {
      background: $blackLight;

      overflow-y: auto;
      max-height: 80vh;

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: blackLight;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: $blackLight;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $white;
      }

      padding: 0 32px 20px 32px;
    }
  }
}

.cartModal {
  padding: 0;

  border-radius: 12px;

  box-shadow: $course-menu-item;

  :global {
    .close {
      display: none;
    }

    .panel-body {
      background: $blackLight;

      padding: 32px;

      @include ifmobile {
        max-height: 100vh;
      }
    }
  }
}

.withOutHeaderModal {
  :global {
    .pure-modal-backdrop .pure-modal {
      width: 500px;
    }

    .panel {
      border-radius: 24px;
      overflow: hidden;
    }

    .panel-body {
      background: $primary;

      padding: 64px;
    }

    .close {
      display: none;
    }
  }
}

.fullScreen {
  width: 100%;

  @include ifmobile {
    padding: 0;
    position: relative;

    margin-top: 75px;

    :global {
      .panel {
        height: 100vh;
      }

      .panel-heading {
        padding: 32px 20px;
        background: $primary-black;

        @include ifmobile {
          padding: 0 20px;
        }
      }

      .panel-title {
        p {
          text-align: center;
        }
      }

      .panel-body {
        padding: 32px 20px;

        height: 100%;
        max-height: 100vh;

        background: $primary-black;

        @include ifmobile {
          padding-bottom: 44px;

          max-height: 100vh;
        }
      }

      .close {
        display: flex;

        top: 24px;
        right: 24px;

        width: 24px;
      }
    }
  }
}
