@use "../../../../assets/scss/main.scss" as *;

.introductionSection {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin: 0 auto;
  max-width: 2000px;
  width: 100%;

  position: relative;
}

.introductionWrapper {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-template-areas:
    ". image"
    "title image"
    "description image"
    "button image";
  align-items: center;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin: 0 auto;
  max-width: $main-container-width;
  width: 100%;

  padding: 120px 15px 100px;

  @include ifdesktopM {
    padding-bottom: 120px 12px 50px;
  }

  @include ifmobile {
    position: relative;

    grid-template-columns: 1fr 0.7fr;

    grid-template-areas:
      "title image"
      "description description"
      "button button";
    row-gap: 24px;

    padding-bottom: 120px 12px 0;
  }
}

.membershipIntroductionWrapper {
  max-width: $main-container-width;
  width: 100%;

  grid-template-columns: 1.2fr 1.1fr;

  padding: 120px 15px 0;

  .buttonWrapper {
    margin-bottom: 164px;
  }

  .introductionTitle {
    font-weight: 600;
  }

  @include ifdesktopM {
    margin-bottom: -200px;
  }

  @include iftablet {
    margin-bottom: 0;
    .buttonWrapper {
      margin-bottom: 0;
    }
  }

  @include ifmobile {
    padding: 60px 12px 0;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;

  width: 1120px;

  @include iftablet {
    width: 720px;

    transform: scale(2);
  }

  @include ifmobile {
    transform: scale(1);

    width: 100%;
  }
}

.introductionText {
  grid-area: title;

  z-index: 1;
}

.introductionTitle {
  font-family: $font-kensmark;
  font-size: 72px;
  font-weight: 400;
  line-height: 93.6px;
  text-align: left;

  margin-bottom: 32px;

  @include ifdesktopM {
    line-height: 60px;
    font-size: 48px;
  }

  @include iftablet {
    line-height: 48px;
  }

  @include ifmobile {
    font-size: 28px;
    font-weight: 300;
    line-height: 33.6px;

    margin: 0;
  }
}

.descriptionWrapper {
  grid-area: description;

  z-index: 1;
}

.introductionDescription {
  max-width: 683px;

  font-size: 20px;
  font-weight: 500;
  line-height: 135%;

  @include iftablet {
    font-size: 16px;
  }

  @include ifmobile {
    font-weight: 400;
    line-height: 19.2px;
  }
}

.buttonWrapper {
  grid-area: button;
  display: flex;
  gap: 5%;

  margin-top: 64px;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
    margin: 0;
  }
}

.membershipImageWrapper {
  grid-area: image;

  position: absolute;
  right: 0;
  top: -200px;

  width: 55%;

  display: flex;
  justify-content: center;

  @include ifdesktopM {
    top: -100px;
    width: 60%;

    img {
      width: 100%;
    }
  }

  @include iftablet {
    img {
      width: 100%;
    }
  }

  @include ifmobile {
    width: 250px;
    order: -1;

    top: -80px;
  }
}

.imageWrapper {
  grid-area: image;

  position: absolute;
  right: 0;

  width: 60%;

  img {
    width: 100%;
  }

  @include ifmobile {
    order: -1;
    top: -125px;

    width: 300px;
  }
}
